import { deepCopy } from './object-helper';

/**
 * Check that the supplied value is divisible by the increment supplied
 * Remainder should be zero
 *
 * @param value
 *
 * @param increment
 * @returns
 */
export function divisibleByIncrementWithoutRemainder(value: number, increment: number): boolean {
  const calc: number = (value % increment) / 100;
  let precision = 0;
  const precisionAry = increment.toString().split('.');
  // add null coalesce and treat null value as zero
  const valueAry = (value ?? '').toString().split('.');

  if (precisionAry.length > 0) {
    if (precisionAry[1] !== undefined) {
      precision = precisionAry[1].length;
    }
  }

  if (+calc.toFixed(precision) === 0) {
    if (valueAry.length > 0) {
      if (valueAry[1] !== undefined) {
        if (valueAry[1].length > precision) return false;
      }
    }

    return true;
  }

  return false;
}

/**
 * Returns alphabet equivalent of number
 *
 * @param numericDigit
 * @returns
 */
export function alphaValueOfNumber(numericDigit: number, isChild?: boolean) {
  let str = '';
  let degree = 0;
  let digit: number = deepCopy(+numericDigit);

  if (digit > 26) {
    degree = Math.floor(numericDigit / 26);

    //skip first increment
    if (numericDigit % 26 === 0) {
      degree = degree - 1;
    }

    const remainder = digit - degree * 26;

    str = alphaValueOfNumber(remainder, true);
    digit = degree;
  }

  str = `${str}${String.fromCharCode(64 + digit)}`;

  //reverse string
  if (!isChild) {
    str = str.split('').reverse().join('');
  }

  return str;
}

/**
 * Return number of Decimal places
 * @param value
 * @returns
 */
export function numericDecimalPlace(value: number | string): number {
  const valueAry = value.toString().split('.');

  if (valueAry.length === undefined) return 0;
  if (valueAry.length === 0 || valueAry.length === 1) return 0;

  return valueAry[1].length;
}
