/**
 * Returns left and top element offset relative to the html body
 * @param el - element for which we are checking the offsets for
 */
export function getElementOffset(el: HTMLElement) {
  const bodyRect = document.body.getBoundingClientRect();
  const rect = el.getBoundingClientRect();

  return {
    left: rect.left - bodyRect.left,
    top: rect.top - bodyRect.top,
  };
}
