/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNullOrUndefined } from '../lib/objects';

function setLoading(context: any, newValue: boolean) {
  const currentState = context.getState();

  return { ...currentState, loading: newValue };
}

export function startLoading(context: any) {
  return setLoading(context, true);
}
export function stopLoading(context: any) {
  return setLoading(context, false);
}

export function addAll(collection: any[], count: number, idKey = 'id') {
  return {
    ids: collection.map((entity) => entity[idKey]),
    entities: collection.reduce((map, entity) => {
      map[entity[idKey]] = entity;

      return map;
    }, {}),
    totalCount: count,
  };
}

export function addLookup(context: any, collection: any[], type: string) {
  const currentState = context.getState();

  currentState[type] = collection;

  return currentState;
}

export function addEntity<T>(context: any, entity: T, idKey = 'id') {
  const currentState = context.getState();

  const existingId = currentState.ids.find((id: unknown) => id === entity[idKey]);

  let ids = [];

  if (!isNullOrUndefined(existingId)) {
    ids = [...currentState.ids];
  } else {
    ids = [...currentState.ids, entity[idKey]];
  }

  return {
    entities: { ...currentState.entities, [entity[idKey]]: entity },
    ids: ids,
  };
}

export function updateEntity<T>(context: any, entity: T, idKey = 'id') {
  const currentState = context.getState();

  return {
    entities: { ...currentState.entities, [entity[idKey]]: entity },
  };
}

export function deleteEntity(context: any, entityId: number) {
  const currentState = context.getState();
  const entityCloned = Object.assign({}, currentState.entities);
  delete entityCloned[entityId];

  return {
    entities: { ...entityCloned },
    ids: [...currentState.ids.filter((id) => id !== entityId)],
  };
}
