import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(value: string, type?: string): SafeHtml {
    switch (type) {
      case 'url':
        return this.sanitized.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
  }
}
